exports.components = {
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-app-templates-tsx": () => import("./../../../src/pages/app-templates.tsx" /* webpackChunkName: "component---src-pages-app-templates-tsx" */),
  "component---src-pages-articles-tsx": () => import("./../../../src/pages/articles.tsx" /* webpackChunkName: "component---src-pages-articles-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-article-template-tsx": () => import("./../../../src/templates/article-template.tsx" /* webpackChunkName: "component---src-templates-article-template-tsx" */),
  "component---src-templates-product-template-tsx": () => import("./../../../src/templates/product-template.tsx" /* webpackChunkName: "component---src-templates-product-template-tsx" */)
}

